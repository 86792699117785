import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Utils
import { ImageOrientation } from "../utils/image-orientation";
import { ConditionalWrapper } from "../utils/conditional-wrapper";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 190px 100px 0 100px;

  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 190px 50px 0 50px;
  }

  @media (max-width: 900px) {
    padding: 110px 0 0 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 50px;

  grid-auto-flow: column;

  width: 100%;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    grid-gap: 0;
    grid-template-columns: unset;
    grid-auto-flow: unset;
  }
`;

const Intro = styled.div`
  width: 100%;

  padding: 0 0 55px 0;
  margin: 0 auto;

  & .text {
    width: 100%;
    max-width: 975px;
    text-align: center;

    padding: 50px 0;
    margin: 0 auto;

    font-size: 32px;
    line-height: 115%;
    letter-spacing: 0.01em;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 1300px) {
      font-size: 28px;
      line-height: 36px;
    }

    @media (max-width: 768px) {
      padding: 40px 20px 0 20px;

      font-size: 24px;
      line-height: 27px;
    }
  }

  @media (max-width: 768px) {
    padding: 0 0 45px 0;
  }
`;

const PageLink = styled.div`
  width: 100%;

  margin: 0 0 100px 0;

  @media (max-width: 768px) {
    margin: 0 0 60px 0;
  }

  & .title h2 {
    font-size: 48px;
    line-height: 55px;
    letter-spacing: 0.01em;

    @media (max-width: 1300px) {
      font-size: 36px;
      line-height: 48px;
    }

    @media (max-width: 900px) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  & .text p {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.04em;

    @media (max-width: 1300px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & img.portrait {
    max-height: 600px;
    object-fit: contain;
  }

  &.small {
    &:nth-of-type(even) {
      & .image {
        grid-column: 1 / 5;
      }

      & .text-container {
        grid-column: 5 / 9;
        text-align: left;
      }
    }

    &:nth-of-type(odd) {
      & .image {
        grid-column: 5 / 9;
      }

      & .text-container {
        grid-column: 1 / 5;
        text-align: right;
      }
    }

    @media (max-width: 900px) {
      &:nth-of-type(odd),
      &:nth-of-type(even) {
        & .image {
          width: 100%;
        }

        & .text-container {
          max-width: 660px;
          margin-left: auto;
          margin-right: auto;

          text-align: center;
        }
      }
    }
  }

  &.large {
    & .image,
    & .text-container {
      grid-column: 2 / 8;

      text-align: center;

      @media (max-width: 900px) {
        width: 100%;
        grid-column: unset;
      }
    }

    & .excerpt-text {
      max-width: 660px;
      margin: 0 auto;
    }
  }

  @media (max-width: 900px) {
    margin: 0 0 60px 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & .publication-date,
  & .read-more {
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.04em;

    margin: 0;
  }

  & .read-more {
    text-transform: uppercase;
  }

  & .excerpt-text {
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0.01em;

    @media (max-width: 1300px) {
      font-size: 28px;
      line-height: 36px;
    }

    @media (max-width: 900px) {
      font-size: 26px;
      line-height: 30px;
    }

    & p:first-of-type {
      margin-top: 20px;
    }

    & p:last-of-type {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1080px) {
    margin: 20px 0 0 0;
  }

  @media (max-width: 900px) {
    padding: 0 20px;
  }
`;

const StoriesContent = ({ data, setPageType }) => {
  useEffect(() => {
    setPageType("stories");
  }, []);

  const content = data.prismicStories.data.articles
    .filter(content => content.article.document !== null)
    .map((content, index) => {
      return (
        <PageLink
          key={content.article.document.id}
          className={content.article_size.toLowerCase()}
        >
          <Grid>
            <div className="image">
              <ConditionalWrapper
                condition={
                  content.article.document.data.full_length_article !== false
                }
                wrapper={children => (
                  <Link to={content.article.document.url}>{children}</Link>
                )}
              >
                {content.article.document.data.vimeo_mp4_file.url !== "" ? (
                  <video
                    src={content.article.document.data.vimeo_mp4_file.url}
                    muted
                    loop
                    autoPlay
                    playsInline
                    preload="metadata"
                  />
                ) : (
                  <>
                    {content.article.document.data.image_thumbnail.fluid !==
                      null && (
                      <img
                        className={ImageOrientation(
                          content.article.document.data.image_thumbnail
                        )}
                        srcSet={
                          content.article.document.data.image_thumbnail.fluid
                            .srcSetWebp
                        }
                        src={
                          content.article.document.data.image_thumbnail.fluid
                            .srcWebp
                        }
                        alt={content.article.document.data.image_thumbnail.alt}
                        loading="lazy"
                      />
                    )}
                  </>
                )}
              </ConditionalWrapper>
            </div>
            <TextContainer className="text-container">
              <ConditionalWrapper
                condition={
                  content.article.document.data.full_length_article !== false
                }
                wrapper={children => (
                  <Link to={content.article.document.url}>{children}</Link>
                )}
              >
                <p className="caslon-doric publication-date">
                  {content.article.document.data.hide_article_date !== true && (
                    <>{content.article.document.data.article_date}</>
                  )}
                </p>

                <div
                  className="excerpt-text"
                  dangerouslySetInnerHTML={{
                    __html: content.article.document.data.text_excerpt.html,
                  }}
                />

                {content.article.document.data.full_length_article !==
                  false && <p className="caslon-doric read-more">Read More</p>}
              </ConditionalWrapper>
            </TextContainer>
          </Grid>
        </PageLink>
      );
    });

  return (
    <>
      <Helmet
        title={`Stories – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `Stories – Park Village`,
          },
          {
            name: "twitter:title",
            content: `Stories – Park Village`,
          },
        ]}
      />
      <Container>
        <Intro>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: data.prismicStories.data.text.html,
            }}
          />
        </Intro>

        {content}
      </Container>
    </>
  );
};

const Stories = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <StoriesContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export default withPreview(Stories);

export const query = graphql`
  {
    prismicStories {
      data {
        text {
          html
        }
        articles {
          article_size
          article {
            document {
              ... on PrismicArticle {
                id
                url
                data {
                  title {
                    text
                  }
                  text_excerpt {
                    html
                  }
                  full_length_article
                  article_date(formatString: "MMMM YYYY")
                  hide_article_date
                  image_thumbnail {
                    alt
                    fluid {
                      srcWebp
                      srcSetWebp
                    }
                    dimensions {
                      width
                      height
                    }
                  }
                  vimeo_mp4_file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
